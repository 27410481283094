@font-face {
    font-family: "IBM Plex Mono";
    src: url("../../public/fonts/IBMPlexMono-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "IBM Plex Mono";
    src: url("../../public/fonts/IBMPlexMono-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "IBM Plex Mono";
    src: url("../../public/fonts/IBMPlexMono-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Ubuntu";
    src: url("../../public/fonts/Ubuntu-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Ubuntu";
    src: url("../../public/fonts/Ubuntu-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Ubuntu";
    src: url("../../public/fonts/Ubuntu-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}