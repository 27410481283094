@import "./css/fonts.css";

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'IBM Plex Mono', monospace;
    color: #FFFFFF;
    font-size: 12px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', sans-serif;
}

.card-cv {
    width: 320px;
    height: 480px;
    border: solid 1px #FFFFFF;
    border-radius: 160px 0 160px 0;
}

.ubuntu {
    font-family: 'Ubuntu', sans-serif;
}

.hero{
    min-height: 90vh;
}

.section {
    min-height: 100vh;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.container {
    @apply mx-auto;
}

.app-icon-size {
    width: 3.5rem !important;
}

.app-stack-icon-size {
    flex: 1 0 40%;
    width: 7rem !important;
}

@media (min-width: 450px) {
    .app-stack-icon-size {
        flex: 1 0 28%;
        width: 7rem !important;
    }
}

@media (min-width: 768px) {
    .app-stack-icon-size {
        flex: 1 0 16%;
    }
    .app-icon-size {
        width: 5rem !important;
    }
}

@media (min-width: 1080px) {
    .app-stack-icon-size {
        flex: 1 0 13%;
    }
}